import Elemental from 'jslib/elemental/elemental';
import ApiManager from 'jslib/api-manager/api-manager';
import events from 'jslib/custom-events';
import analyticsEvents from 'jslib/data-events';

Elemental.Components.Navigation = class Navigation extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.css = {
            classes: {
                navOpen: 'nav-open',
                navOpenChild: 'nav-open--child',
                open: 'open',
                childOpen: 'open--child',
            },
            selectors: {
                homeLink: '.navbar-brand',
                searchLink: '.navbar-search',
                navbarSlide: '.navbar-slide',
                navbarSlideSubnav: '.navbar-slide--subnav',
                navbarToggler: '.navbar-toggler',
                navbarTrigger: '.navbar-trigger',
                navbarToggleText: '.navbar-toggler-text',
                navbarOverlay: '.navbar-overlay',
                navLink: '.nav-link',
                navbarSlideSubnavCloseButton: '.navbar-slide__subnav-close',
                navbarComparisonContainer: '#subnav-comparisons .navbar-slide__inner',
                navbarPlaylistContainer: '#subnav-playlists .navbar-slide__inner',
                navbarAccountContainer: '.navbar-account',
                benchmarkContainer: '.navbar-benchmark',
                playlistCreateForm: '.form--create-playlist',
                playlistInner: '.navbar-playlists__inner',
                autocomplete: '[data-pulse-component="autocomplete"]',
            },
        };

        this.searchLinkEl = this.element.querySelector(this.css.selectors.searchLink);
        this.homeLinkEl = this.element.querySelector(this.css.selectors.homeLink);
        this.navbarOverlayEl = this.element.querySelector(this.css.selectors.navbarOverlay);
        this.navbarTogglerEl = this.element.querySelector(this.css.selectors.navbarToggler);
        this.comparisonContainerEl = this.element.querySelector(this.css.selectors.navbarComparisonContainer);
        this.playlistContainerEl = this.element.querySelector(this.css.selectors.navbarPlaylistContainer);

        this.navLinkEls = this.element.querySelectorAll(this.css.selectors.navLink);
        this.navbarTriggerEls = this.bodyEl.querySelectorAll(this.css.selectors.navbarTrigger);
        this.subnavCloseButtonEls = this.bodyEl.querySelectorAll(this.css.selectors.navbarSlideSubnavCloseButton);

        this.navDrawer = this.element.dataset.navDrawer ? true : false;
        this.currentVisibleNavLevel = this.navDrawer ? 1 : 0;
        this.apiManager = new ApiManager();
    }

    setEventListeners() {
        if (this.searchLinkEl) {
            this.searchLinkEl.addEventListener('click', this.onSearchClick.bind(this));
        }

        if (this.homeLinkEl) {
            this.homeLinkEl.addEventListener(
                'click',
                this.trackEvent.bind(this, analyticsEvents.navigation.homeClick, {})
            );
        }

        if (this.navbarOverlayEl) {
            this.navbarOverlayEl.addEventListener('click', this.onNavbarOverlayClick.bind(this));
        }

        if (this.navbarTogglerEl) {
            this.navbarTogglerEl.addEventListener('click', this.onNavbarTogglerClick.bind(this));
        }

        this.navLinkEls.forEach((linkEl) => linkEl.addEventListener('click', this.onNavLinkClick.bind(this)));
        this.navbarTriggerEls.forEach((triggerEl) =>
            triggerEl.addEventListener('click', this.onNavbarTriggerClick.bind(this))
        );
        this.subnavCloseButtonEls.forEach((buttonEl) =>
            buttonEl.addEventListener('click', this.onSubnavCloseButtonClick.bind(this))
        );

        this.bodyEl.addEventListener(events.navigation.closeAll, this.closeAllNavs.bind(this));
    }

    onNavLinkClick(event) {
        const parentNavEl = event.currentTarget.closest('[data-nav-level]');
        const eventData = {
            name: event.currentTarget.textContent.trim(),
            type: event.currentTarget.dataset.linkType,
            context: parentNavEl ? parentNavEl.dataset.namespace : '',
        };

        this.trackEvent(analyticsEvents.navigation.linkClick, eventData);
    }

    onSearchClick() {
        this.emitEvent(this.bodyEl, events.navigation.closeAll);
    }

    onSubnavCloseButtonClick(event) {
        const targetLevel = event.currentTarget.dataset.targetLevel;
        const targetNavEl = event.currentTarget.closest(
            `${this.css.selectors.navbarSlide}[data-nav-level="${targetLevel}"]`
        );
        const targetTriggerEl = this.element.querySelector(
            `${this.css.selectors.navbarTrigger}[data-nav-target="${targetNavEl.id}"]`
        );

        targetTriggerEl.click();
    }

    onNavbarOverlayClick(event) {
        if (this.navDrawer && window.matchMedia('(min-width: 996px)').matches) {
            this.closeAllSubnavs();
        } else {
            this.closeSubnavElements(1);
            this.bodyEl.classList.remove(this.css.classes.navOpen);
        }
    }

    onNavbarTogglerClick(event) {
        if (this.navDrawer && window.matchMedia('(min-width: 996px)').matches) {
            if (this.getCurrentVisibleNavLevel() > 1 && event.currentTarget.dataset.master === 'true') {
                // Kill the event so the toggler doesn't close the top-level nav,
                // because we want to leave it open unless the user closes it specifically
                event.preventDefault();
                event.stopPropagation();
            } else {
                this.bodyEl.classList.toggle(this.css.classes.navOpen);
                this.emitEvent(
                    this.bodyEl,
                    events.carousel.resetBreakpoints,
                    this.bodyEl.classList.contains(this.css.classes.navOpen)
                );
            }
        } else {
            this.bodyEl.classList.toggle(this.css.classes.navOpen);
        }
    }

    onNavbarTriggerClick(event) {
        const isOpen = JSON.parse(event.currentTarget.getAttribute('aria-expanded'));
        const target = event.currentTarget.dataset.navTarget;
        const targetEl = document.getElementById(target);
        const targetLevel = parseInt(targetEl.dataset.navLevel, 10);
        const parentEl = event.currentTarget.closest('[data-nav-level]');
        let visibleNavLevel = this.getCurrentVisibleNavLevel();

        if (
            this.navDrawer &&
            window.matchMedia('(min-width: 996px)').matches &&
            visibleNavLevel > 1 &&
            event.currentTarget.dataset.master === 'true'
        ) {
            // Close all subnav panes
            this.closeAllSubnavs();

            // Just return out; correct behaviour will be picked up by the toggler
            return;
        }

        if (isOpen && visibleNavLevel === targetLevel) {
            // Closing an already-open nav pane by clicking on its trigger, so reduce visible nav level by 1
            visibleNavLevel--;
        } else {
            visibleNavLevel = targetLevel;
        }

        this.setCurrentVisibleNavLevel(visibleNavLevel);
        this.closeSubnavElements(targetLevel);
        this.toggleNavElement(targetEl, !isOpen);
        this.updateTriggerAriaRoles(event.currentTarget, !isOpen);
        this.updateTogglerText(!isOpen);

        this.bodyEl.classList.toggle(this.css.classes.navOpenChild, this.getCurrentVisibleNavLevel() > 1);

        this.trackEvent(analyticsEvents.navigation.subnavTriggerClick, {
            name: event.currentTarget.textContent.trim(),
            context: parentEl.dataset.namespace,
            subnavExpanded: !isOpen,
            subnavLevel: targetLevel,
        });
    }

    renderAccountNav() {
        if (!this.globalObject.user.loggedIn) {
            return;
        }

        const accountContainerEl = this.element.querySelector(this.css.selectors.navbarAccountContainer);
        const firstname = this.globalObject.user.firstname;
        const initial = firstname.slice(0, 1);

        accountContainerEl.querySelector('.initial').textContent = initial;
        accountContainerEl.querySelector('.firstname').textContent = firstname;
    }

    toggleNavElement(targetNavEl, isOpen) {
        const targetLevel = targetNavEl.dataset.navLevel;
        const parentNavEl = targetNavEl.closest(
            `${this.css.selectors.navbarSlide}[data-nav-level="${targetLevel - 1}"]`
        );

        if (targetLevel > 1) {
            parentNavEl.classList.toggle(this.css.classes.childOpen, isOpen);
        }

        targetNavEl.classList.toggle(this.css.classes.open, isOpen);
    }

    closeSubnavElements(targetLevel) {
        const subNavEls = this.bodyEl.querySelectorAll(`[data-nav-level="${targetLevel}"].${this.css.classes.open}`);

        subNavEls.forEach((subNavEl) => {
            const triggerEl = this.element.querySelector(`[data-nav-target="${subNavEl.id}"]`);

            subNavEl.classList.remove(this.css.classes.open, this.css.classes.childOpen);

            subNavEl
                .querySelectorAll(this.css.selectors.navbarSlideSubnav)
                .forEach((slideEl) => slideEl.classList.remove(this.css.classes.open, this.css.classes.childOpen));

            subNavEl
                .querySelectorAll(this.css.selectors.navbarTrigger)
                .forEach((navbarTriggerEl) => navbarTriggerEl.setAttribute('aria-expanded', false));

            this.updateTriggerAriaRoles(triggerEl, false);
            this.updateTogglerText(false);
        });
    }

    closeAllNavs() {
        if (this.navbarTogglerEl.getAttribute('aria-expanded') === 'true') {
            this.navbarTogglerEl.click();
        }
    }

    closeAllSubnavs() {
        // Bit crude, but it'll do for now
        this.closeSubnavElements(3);
        this.closeSubnavElements(2);
        this.setCurrentVisibleNavLevel(1);

        this.element
            .querySelector(`${this.css.selectors.navbarSlide}.${this.css.classes.open}`)
            .classList.remove(this.css.classes.childOpen);
        this.bodyEl.classList.remove(this.css.classes.navOpenChild);
    }

    updateTriggerAriaRoles(triggerEl, isOpen) {
        triggerEl.setAttribute('aria-expanded', isOpen);
    }

    updateTogglerText(isOpen) {
        const navTogglerEl = document.querySelector(this.css.selectors.navbarToggler);
        const togglerTextEl = navTogglerEl.querySelector(this.css.selectors.navbarToggleText);
        let text = navTogglerEl.dataset.openText;

        if (isOpen && this.getCurrentVisibleNavLevel() > 1) {
            text = togglerTextEl.dataset.closeText;
        }

        togglerTextEl.textContent = text;
    }

    getCurrentVisibleNavLevel() {
        return this.currentVisibleNavLevel;
    }

    setCurrentVisibleNavLevel(level) {
        this.currentVisibleNavLevel = level;
    }

    trackEvent(eventName, eventData = {}) {
        this.emitEvent(this.bodyEl, events.analytics.track, {
            name: eventName,
            data: eventData,
        });
    }

    init() {
        if (this.navbarTogglerEl) {
            this.renderAccountNav();

            if (this.navDrawer && !window.matchMedia('(min-width: 996px)').matches) {
                this.closeAllNavs();
            }
        }
    }

    render() {
        this.setVars();
        this.setEventListeners();
        this.init();
    }
};
